"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAverageTimeBetweenCalls = exports.getAverageActualCallDurationString = exports.getAverageBillableCallDurationString = exports.getAverageEfficiencyPerDayString = exports.getTotalBillableCallDurationString = exports.getActualCallDurationString = exports.formatISODateForFileName = exports.generateCSVForJoTable = exports.formateISODateForFileName = exports.uploadCSV = exports.downloadCSV = exports.formatUSDCentsForDisplay = exports.createDebouncer = exports.shortCircuitFilterByGivenLength = exports.formatISODateForHTMLInput = exports.removeFormError = exports.getContactNameForDisplay = exports.getUserFullName = exports.calculateNumPagesForTable = exports.generateFirstItemNumberOnPageForTable = exports.generatePagingResultsTextForTable = exports.paginateItems = exports.raiseCustomEvent = exports.joAudioPopUp = exports.joPopUp = exports.joAlert = exports.joConfirm = exports.isSet = exports.handleError = exports.assertSucceeded = exports.assertIsSet = exports.assertIsDefined = exports.fetchDurationEventsForJill = exports.raiseSetterAction = exports.raiseAction = exports.formatPhoneNumberToE164 = exports.isPhoneNumberInE164Format = exports.formatE164PhoneNumberForDisplay = exports.wasDoubleClicked = exports.loadChart = exports.getIdleTimeAfterCall = exports.getIdleTimeBeforeCall = exports.getActionItemTimeForCall = exports.millisecondsToMinutes = exports.millisecondsToHoursMinutesSecondsLong = exports.millisecondsToHoursMinutesSeconds = exports.removeAuthorizationHeaderInGraphqlPlayground = exports.setAuthorizationHeaderInGraphqlPlayground = exports.getBearer = exports.navigate = void 0;
exports.sendCallScreenRefreshedDuringCallAlert = exports.formatMoneyInUSDCents = exports.getAllMonthNames = exports.getYearsTillCurrentYear = exports.getCurrentYear = exports.getCurrentMonthName = exports.sortActionItemsForTable = exports.cloneDeep = exports.setPropsReducer = exports.getEndOfMonthForForMonth = exports.getStartOfMonthForForMonth = exports.getForMonthForDate = exports.sendRouterRequest = exports.sortItemsByTimeStamp = exports.assertHasTimestamp = exports.userIsJill = exports.shouldShowContactSearchFunctionality = exports.closeSubscriptionsIfNecessary = exports.refetchGetItems = exports.convertDateInputDateStringToLocalTimeISOString = exports.getDateStringForDateInputInLocalTime = exports.inputValuesHaveChanged = exports.sendNewActionItemNoteEmails = exports.isOnDashboard = exports.isOnCallScreen = exports.getIsOnCallScreen = exports.randomBytes = exports.generateURLSafeBase64String = exports.createActionItemSlug = exports.isAllowedToUpdateJillPods = exports.shouldDisableCallActionButtonDialPadDuringCall = exports.shouldDisableCallActionButtonsDuringCall = exports.startOutgoingCallForActionItem = exports.checkIfZipCodeIsValid = exports.isInternalTransfer = exports.isLoading = exports.deepLeftCheck = exports.isAllowedToUpdateUser = exports.authorizedRender = exports.isInAuthRole = exports.getFirstPhoneNumberForContact = exports.getPhoneNumberForOutgoingCallForActionItem = exports.wait = exports.getCompanyServiceLineItems = exports.entityArraysEqual = exports.generateCSVStringForHighLevelStatsForMonths = exports.generateCSVBodyForHighLevelStats = exports.generateCSVForHighLevelStatses = exports.generateAndDownloadCSVForHighLevelStatses = exports.getAverageActionItemTimeString = void 0;
exports.getStartOfDate = exports.getStartOfToday = exports.shouldHideFromCompanyUser = exports.isMobileView = exports.makeDateTwoChar = exports.doesObjectBContainEverythingInObjectAExcludingFormErrors = exports.areObjectsEqualMinusFormErrors = exports.hasPermissionToManageJillProfilePictures = exports.formatDateForActivityLog = exports.isUserAMemberOfJillsOffice = exports.trimExtraWhiteSpace = exports.sendActionItemPushNotification = exports.getIsUserMemberOfCompanyAndInAuthRole = exports.getIsCompanyOwner = exports.getIsCompanyUser = exports.isNewCompanyId = exports.getYearFromForMonth = exports.capitalizeFirstLetter = exports.getMonthFromForMonth = exports.getBillingDateRangeFromForMonth = exports.convertCentsToUSDollarsString = exports.convertCentsToDollars = exports.getEndOfMonth = exports.getForMonth = exports.getCurrentForMonth = exports.getUTCDate = void 0;
const common_1 = require("@augment-vir/common");
const dequal_1 = require("dequal");
const jo_alert_1 = require("../ui/elements/jo-alert");
require("../ui/elements/jo-alert");
const jo_audio_popup_1 = require("../ui/elements/jo-audio-popup");
require("../ui/elements/jo-audio-popup");
const jo_confirm_1 = require("../ui/elements/jo-confirm");
require("../ui/elements/jo-confirm");
const jo_popup_1 = require("../ui/elements/jo-popup/jo-popup");
const get_auth_keys_from_local_storage_1 = require("./get-auth-keys-from-local-storage");
const logout_detector_1 = require("./logout-detector");
const auth_rules_1 = require("../utilities/auth-rules");
const error_handling_1 = require("../utilities/error-handling");
const constants_1 = require("./constants");
const cryptography_1 = require("./cryptography");
const graphql_1 = require("./graphql");
const store_1 = require("./store");
const twilio_1 = require("./twilio");
const add_data_to_error_1 = require("./logging/error-handling/add-data-to-error");
const mapped_env_variables_1 = require("../utilities/environment/mapped-env-variables");
function navigate(path, inPlace) {
    // TODO we could probably generalize this code a bit more
    if (store_1.GlobalStore.inputValues.companyDetails.inputValuesUnsavedChanges === true) {
        joAlert(`Unsaved Changes`, 'You have unsaved changes on the company details page. Save or cancel those changes.');
        return false;
    }
    if (store_1.GlobalStore.inputValues.callInstructionsGeneralSetup.inputValuesUnsavedChanges === true) {
        joAlert(`Unsaved Changes`, 'You have unsaved changes on the Call Instructions: General Setup page. Save or cancel those changes.');
        return false;
    }
    if (store_1.GlobalStore.inputValues.servicesPricing.inputValuesUnsavedChanges === true) {
        joAlert(`Unsaved Changes`, 'You have unsaved changes on the Call Instructions: Services/Pricing page. Save or cancel those changes.');
        return false;
    }
    if (store_1.GlobalStore.inputValues.contactSave.inputValuesUnsavedChanges === true) {
        joAlert(`Unsaved Changes`, 'You have unsaved changes on the contact page. Save or cancel those changes.');
        return false;
    }
    const previousFullPath = getCurrentFullUrlPath();
    if (previousFullPath === undefined) {
        throw new Error('previousFullPath is undefined');
    }
    if (inPlace === true) {
        history.replaceState({}, '', path);
    }
    else {
        history.pushState({}, '', path);
    }
    store_1.GlobalStore.dispatch({
        type: 'CHANGE_ROUTE',
        pathname: window.location.pathname,
        search: window.location.search,
        previousFullPath,
    });
    return true;
}
exports.navigate = navigate;
function getCurrentFullUrlPath() {
    return window.location.href.split(window.location.origin)?.[1];
}
async function getMillisecondsSignature(clientPrivateKey) {
    const message = new Date().getTime().toString();
    return clientPrivateKey == undefined
        ? 'NO_SIGNATURE'
        : await (0, cryptography_1.signMessage)(message, clientPrivateKey);
}
async function getBearer() {
    const { clientPublicKey, clientPrivateKey, csrfToken } = (0, get_auth_keys_from_local_storage_1.getAuthKeysFromLocalStorage)();
    const millisecondsSignature = await getMillisecondsSignature(clientPrivateKey);
    const timestamp = {
        millisecondsSignature,
    };
    return {
        clientPublicKey,
        csrfToken,
        timestamp,
    };
}
exports.getBearer = getBearer;
async function setAuthorizationHeaderInGraphqlPlayground() {
    const bearer = await getBearer();
    const graphqlPlaygroundString = window.localStorage.getItem('graphql-playground');
    const graphqlPlayground = graphqlPlaygroundString
        ? JSON.parse(graphqlPlaygroundString)
        : 'NOT_FOUND';
    if (graphqlPlayground !== 'NOT_FOUND') {
        const settings = JSON.parse(graphqlPlayground.settingsString);
        const modifiedSettings = {
            ...settings,
            ['request.credentials']: 'include',
        };
        const modifiedSettingsString = JSON.stringify(modifiedSettings);
        const modifiedGraphqlPlayground = {
            ...graphqlPlayground,
            settingsString: modifiedSettingsString,
            workspaces: {
                ...graphqlPlayground.workspaces,
                [mapped_env_variables_1.currentMappedEnvVariables.graphqlPlaygroundWorkspace]: {
                    ...graphqlPlayground.workspaces[mapped_env_variables_1.currentMappedEnvVariables.graphqlPlaygroundWorkspace],
                    sessions: {
                        ...graphqlPlayground.workspaces[mapped_env_variables_1.currentMappedEnvVariables.graphqlPlaygroundWorkspace].sessions,
                        sessions: Object.entries(graphqlPlayground.workspaces[mapped_env_variables_1.currentMappedEnvVariables.graphqlPlaygroundWorkspace].sessions.sessions).reduce((result, sessionEntry) => {
                            const key = sessionEntry[0];
                            const value = sessionEntry[1];
                            return {
                                ...result,
                                [key]: {
                                    ...value,
                                    endpoint: mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint,
                                    headers: JSON.stringify({
                                        Authorization: `Bearer ${JSON.stringify(bearer)}`,
                                    }),
                                },
                            };
                        }, {}),
                    },
                },
            },
        };
        window.localStorage.setItem('graphql-playground', JSON.stringify(modifiedGraphqlPlayground));
    }
}
exports.setAuthorizationHeaderInGraphqlPlayground = setAuthorizationHeaderInGraphqlPlayground;
function removeAuthorizationHeaderInGraphqlPlayground() {
    const graphqlPlaygroundString = window.localStorage.getItem('graphql-playground');
    const graphqlPlayground = graphqlPlaygroundString
        ? JSON.parse(graphqlPlaygroundString)
        : 'NOT_FOUND';
    if (graphqlPlayground !== 'NOT_FOUND') {
        const modifiedGraphqlPlayground = {
            ...graphqlPlayground,
            workspaces: {
                ...graphqlPlayground.workspaces,
                [mapped_env_variables_1.currentMappedEnvVariables.graphqlPlaygroundWorkspace]: {
                    ...graphqlPlayground.workspaces[mapped_env_variables_1.currentMappedEnvVariables.graphqlPlaygroundWorkspace],
                    sessions: {
                        ...graphqlPlayground.workspaces[mapped_env_variables_1.currentMappedEnvVariables.graphqlPlaygroundWorkspace].sessions,
                        sessions: Object.entries(graphqlPlayground.workspaces[mapped_env_variables_1.currentMappedEnvVariables.graphqlPlaygroundWorkspace].sessions.sessions).reduce((result, sessionEntry) => {
                            const key = sessionEntry[0];
                            const value = sessionEntry[1];
                            return {
                                ...result,
                                [key]: {
                                    ...value,
                                    endpoint: mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint,
                                    headers: JSON.stringify({}),
                                },
                            };
                        }, {}),
                    },
                },
            },
        };
        window.localStorage.setItem('graphql-playground', JSON.stringify(modifiedGraphqlPlayground));
    }
}
exports.removeAuthorizationHeaderInGraphqlPlayground = removeAuthorizationHeaderInGraphqlPlayground;
function millisecondsToHoursMinutesSeconds(milliseconds) {
    if (isNaN(milliseconds)) {
        return '0 sec';
    }
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = hours === 0
        ? Math.floor(milliseconds / 60000)
        : Math.floor((milliseconds % 3600000) / 60000);
    const seconds = minutes === 0 ? Math.floor(milliseconds / 1000) : Math.floor((milliseconds % 60000) / 1000);
    return `${hours === 0 ? '' : `${hours} hr `}${minutes === 0 ? '' : `${minutes} min `}${seconds} sec`;
}
exports.millisecondsToHoursMinutesSeconds = millisecondsToHoursMinutesSeconds;
function millisecondsToHoursMinutesSecondsLong(milliseconds) {
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = hours === 0
        ? Math.floor(milliseconds / 60000)
        : Math.floor((milliseconds % 3600000) / 60000);
    const seconds = minutes === 0 ? Math.floor(milliseconds / 1000) : Math.floor((milliseconds % 60000) / 1000);
    if (hours === 0 && minutes > 0 && seconds === 0) {
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    }
    return `${hours === 0 ? '' : `${hours} hours `}${minutes === 0 ? '' : `${minutes} min `}${seconds} seconds`;
}
exports.millisecondsToHoursMinutesSecondsLong = millisecondsToHoursMinutesSecondsLong;
function millisecondsToMinutes(milliseconds) {
    return `${Math.floor(milliseconds / 60000)} min`;
}
exports.millisecondsToMinutes = millisecondsToMinutes;
// TODO this is copied exactly in jills-office-node/services/high-level-stats.ts and jills-office-web/services/utilities
function getActionItemTimeForCall(call) {
    if (call.duration_events === null || call.duration_events === undefined) {
        return 0;
    }
    const callEnd = call.duration_events.find((durationEvent) => {
        return durationEvent.description === 'CALL_END';
    });
    if (callEnd === undefined) {
        return 0;
    }
    const actionItemSaved = call.duration_events.find((durationEvent) => {
        return durationEvent.description === 'ACTION_ITEM_SAVED';
    });
    if (actionItemSaved === undefined) {
        return 0;
    }
    const delta = new Date(actionItemSaved.timestamp).getTime() - new Date(callEnd.timestamp).getTime();
    return delta;
}
exports.getActionItemTimeForCall = getActionItemTimeForCall;
function getIdleTimeBeforeCall(call, calls) {
    return getIdleTimeForCall(call, calls, 'BEFORE');
}
exports.getIdleTimeBeforeCall = getIdleTimeBeforeCall;
function getIdleTimeAfterCall(call, calls) {
    return getIdleTimeForCall(call, calls, 'AFTER');
}
exports.getIdleTimeAfterCall = getIdleTimeAfterCall;
function getIdleTimeForCall(call, calls, beforeOrAfter) {
    const sortedCalls = [...calls].sort((call1, call2) => {
        if (call1.created_at > call2.created_at) {
            return 1;
        }
        if (call1.created_at < call2.created_at) {
            return -1;
        }
        return 0;
    });
    const callIndex = sortedCalls.findIndex((currentCall) => {
        return currentCall.id === call.id;
    });
    const callBeforeOrAfterIndex = callIndex + (beforeOrAfter === 'BEFORE' ? -1 : 1);
    const callBeforeOrAfter = calls[callBeforeOrAfterIndex];
    if (callBeforeOrAfter === undefined) {
        return 'N/A';
    }
    const durationEventOfCall = call.duration_events.find((durationEvent) => {
        if (beforeOrAfter === 'BEFORE') {
            return durationEvent.type === 'START' && durationEvent.description === 'CALL_START';
        }
        else {
            return (durationEvent.type === 'STOP' &&
                durationEvent.description === 'ACTION_ITEM_SAVED');
        }
    });
    const durationEventOfCallBeforeOrAfter = callBeforeOrAfter.duration_events.find((durationEvent) => {
        if (beforeOrAfter === 'BEFORE') {
            return (durationEvent.type === 'STOP' &&
                durationEvent.description === 'ACTION_ITEM_SAVED');
        }
        else {
            return durationEvent.type === 'START' && durationEvent.description === 'CALL_START';
        }
    });
    if (durationEventOfCall === undefined || durationEventOfCallBeforeOrAfter === undefined) {
        return 'N/A';
    }
    if (beforeOrAfter === 'BEFORE') {
        return (new Date(durationEventOfCall.timestamp).getTime() -
            new Date(durationEventOfCallBeforeOrAfter.timestamp).getTime());
    }
    else {
        return (new Date(durationEventOfCallBeforeOrAfter.timestamp).getTime() -
            new Date(durationEventOfCall.timestamp).getTime());
    }
}
function loadChart(element, highLevelStatses, canvasId, label, highLevelStatsProperty) {
    // TODO if we don't destroy the canvas, weird things happen when resetting the data
    const div = element.querySelector(`#${canvasId}-div`);
    if (div === null) {
        return;
    }
    div.innerHTML = `<canvas id="${canvasId}"></canvas>`;
    const ctx = element.querySelector(`#${canvasId}`).getContext('2d');
    new Chart(ctx, {
        type: 'line',
        data: {
            labels: highLevelStatses.highLevelStatsForMonths.reduce((result, highLevelStatsForMonth) => {
                return [
                    ...result,
                    ...highLevelStatsForMonth.highLevelStatsForDays.map(highLevelStatsForDay => {
                        return new Date(highLevelStatsForDay.dateInDay).toLocaleDateString();
                    }),
                ];
            }, []),
            datasets: [
                {
                    label,
                    data: highLevelStatses.highLevelStatsForMonths.reduce((result, highLevelStatsForMonth) => {
                        return [
                            ...result,
                            ...highLevelStatsForMonth.highLevelStatsForDays.map(highLevelStatsForDay => {
                                if (highLevelStatsProperty === 'actualCallDuration' ||
                                    highLevelStatsProperty === 'billableCallDuration' ||
                                    highLevelStatsProperty ===
                                        'averageBillableCallDuration' ||
                                    highLevelStatsProperty ===
                                        'averageActualCallDuration' ||
                                    highLevelStatsProperty === 'averageTimeBetweenCalls' ||
                                    highLevelStatsProperty === 'averageActionItemTime') {
                                    return Math.floor(parseInt(highLevelStatsForDay.highLevelStats[highLevelStatsProperty].toString()) / 60000);
                                }
                                if (highLevelStatsProperty === 'numCalls' ||
                                    highLevelStatsProperty === 'averageEfficiencyPerDay') {
                                    return highLevelStatsForDay.highLevelStats[highLevelStatsProperty];
                                }
                                throw new Error('Not a known property');
                            }),
                        ];
                    }, []),
                },
            ],
        },
    });
}
exports.loadChart = loadChart;
function wasDoubleClicked(e) {
    return e.detail >= 2;
}
exports.wasDoubleClicked = wasDoubleClicked;
function formatE164PhoneNumberForDisplay(phoneNumber) {
    if (phoneNumber === null || phoneNumber === undefined || phoneNumber === '') {
        return '';
    }
    // TODO this will only work for the US/Canada country code, find out if we we allow for other country codes
    const e164PhoneNumber = isPhoneNumberInE164Format(phoneNumber)
        ? phoneNumber
        : formatPhoneNumberToE164(phoneNumber);
    const phoneNumberWithoutCountryCode = e164PhoneNumber.replace('+1', '');
    return `${phoneNumberWithoutCountryCode.slice(0, 3)}-${phoneNumberWithoutCountryCode.slice(3, 6)}-${phoneNumberWithoutCountryCode.slice(6, 10)}`;
}
exports.formatE164PhoneNumberForDisplay = formatE164PhoneNumberForDisplay;
function isPhoneNumberInE164Format(phoneNumber) {
    return /(\+1)[0-9]{10}/.test(phoneNumber) === true;
}
exports.isPhoneNumberInE164Format = isPhoneNumberInE164Format;
function formatPhoneNumberToE164(phoneNumber) {
    if (phoneNumber === null || phoneNumber === undefined || phoneNumber === '') {
        return '';
    }
    return '+1' + phoneNumber.replace(/(\+1)|\)|\(|-|\s/g, '');
}
exports.formatPhoneNumberToE164 = formatPhoneNumberToE164;
function raiseAction(element, action) {
    element.dispatchEvent(new CustomEvent('action', {
        detail: action,
        bubbles: true,
        composed: true,
    }));
}
exports.raiseAction = raiseAction;
function raiseSetterAction(element, key, value) {
    element.dispatchEvent(new CustomEvent('setteraction', {
        detail: {
            key,
            value,
        },
        bubbles: true,
        composed: true,
    }));
}
exports.raiseSetterAction = raiseSetterAction;
// TODO this function will not be necessary once graphback can allow pagination and ordering on relations in the selection set
async function fetchDurationEventsForJill(jillId) {
    const gqlResult = await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
            query ($jillId: Int!) {
                findDuration_events(
                    filter: {user_id: {eq: $jillId}}
                    orderBy: {field: "created_at", order: DESC}
                    page: {limit: 1}
                ) {
                    items {
                        description
                    }
                }
            }
        `, {
        jillId,
    });
    return gqlResult.data.findDuration_events.items;
}
exports.fetchDurationEventsForJill = fetchDurationEventsForJill;
function assertIsDefined(val, variableName, callback) {
    if (val !== null && val !== undefined) {
        return;
    }
    const message = `${variableName || 'Something'} is not defined`;
    if (!callback)
        handleError(message);
    else
        callback(message);
}
exports.assertIsDefined = assertIsDefined;
function assertIsSet(value, callback, variableName) {
    try {
        if (value === 'NOT_SET' ||
            value === 'NOT_FOUND' ||
            (typeof value === 'symbol' && value.toString() === `Symbol(NOT_SET)`) ||
            value === null ||
            value === undefined) {
            if (callback)
                callback(`${variableName || 'something'} is not properly set`);
            else
                handleError(`${variableName || 'something'} is not properly set`);
            throw new Error(`${variableName || 'something'} is not properly set`);
        }
    }
    catch (error) {
        throw new Error(error);
    }
}
exports.assertIsSet = assertIsSet;
function assertSucceeded(joResult, errorCallback, element, name) {
    if (joResult.succeeded === true) {
        return;
    }
    const joFailure = {
        ...joResult,
        name: name ?? '',
    };
    let errorCallbackError;
    if (errorCallback) {
        try {
            errorCallback(joFailure);
        }
        catch (error) {
            errorCallbackError = error;
        }
    }
    handleError(errorCallbackError ?? joFailure, element);
}
exports.assertSucceeded = assertSucceeded;
async function handleError(errorInfo, element) {
    let errorToThrow = undefined;
    if ((0, common_1.typedHasProperty)(errorInfo, 'developerMessage')) {
        errorToThrow = new Error(String(errorInfo.developerMessage));
    }
    if (!errorToThrow) {
        errorToThrow = (0, common_1.ensureError)(errorInfo);
    }
    joAlert('Error', (0, common_1.extractErrorMessage)(errorToThrow));
    resetElementLoadingAndSavingState(element);
    (0, add_data_to_error_1.addErrorDataAndThrow)(errorToThrow, {
        extraData: {
            errorInfo,
            element: element?.tagName,
        },
    });
}
exports.handleError = handleError;
function resetElementLoadingAndSavingState(element) {
    if (element === null || element === undefined)
        return;
    setPropValueOnStore(element.store, 'loading', false);
    setPropValueOnStore(element.store, 'saving', false);
}
function setPropValueOnStore(store, propName, value) {
    const storeTarget = getProxyTarget(store);
    if (storeTarget.hasOwnProperty(propName)) {
        store[propName] = value;
    }
}
function getProxyTarget(proxyObject) {
    return Object.assign({}, proxyObject);
}
function isSet(value) {
    if (value === 'NOT_SET' ||
        (typeof value === 'symbol' && value.toString() === `Symbol(NOT_SET)`) ||
        value === null ||
        value === undefined) {
        return false;
    }
    return true;
}
exports.isSet = isSet;
async function joConfirm(confirmTitle, confirmMessage) {
    return new Promise(resolve => {
        const joConfirm = new jo_confirm_1.JOConfirm(confirmTitle, confirmMessage);
        document.body.appendChild(joConfirm);
        joConfirm.addEventListener('ok', () => {
            document.body.removeChild(joConfirm);
            resolve(true);
        });
        joConfirm.addEventListener('cancel', () => {
            document.body.removeChild(joConfirm);
            resolve(false);
        });
    });
}
exports.joConfirm = joConfirm;
async function joAlert(alertTitle, alertMessage) {
    return new Promise(resolve => {
        const joAlert = new jo_alert_1.JOAlert(alertTitle, alertMessage);
        document.body.appendChild(joAlert);
        joAlert.addEventListener('ok', () => {
            document.body.removeChild(joAlert);
            resolve();
        });
    });
}
exports.joAlert = joAlert;
async function joPopUp(params) {
    return new Promise(resolve => {
        const joPopUp = new jo_popup_1.JOPopUp(params);
        document.body.appendChild(joPopUp);
        joPopUp.addEventListener('ok', () => {
            document.body.removeChild(joPopUp);
            resolve(true);
        });
        joPopUp.addEventListener('cancel', () => {
            document.body.removeChild(joPopUp);
            resolve(false);
        });
    });
}
exports.joPopUp = joPopUp;
async function joAudioPopUp(src) {
    return new Promise(resolve => {
        const joAudio = new jo_audio_popup_1.JOAudioPopUp(src);
        document.body.appendChild(joAudio);
        joAudio.addEventListener('ok', () => {
            document.body.removeChild(joAudio);
            resolve();
        });
    });
}
exports.joAudioPopUp = joAudioPopUp;
function raiseCustomEvent(element, eventName, detail) {
    element.dispatchEvent(new CustomEvent(eventName, {
        detail,
        bubbles: true,
        composed: true,
    }));
}
exports.raiseCustomEvent = raiseCustomEvent;
function paginateItems(items, currentPage, maxNumItemsPerPage) {
    const startingPositionOnPage = getStartingPostionOnTablePage(currentPage, maxNumItemsPerPage);
    const endingPositionOnPage = startingPositionOnPage + maxNumItemsPerPage;
    const paginatedItems = items.slice(startingPositionOnPage, endingPositionOnPage);
    return paginatedItems;
}
exports.paginateItems = paginateItems;
function getStartingPostionOnTablePage(currentPage, maxNumItemsPerPage) {
    if (currentPage === 1) {
        return 0;
    }
    else {
        return (currentPage - 1) * maxNumItemsPerPage;
    }
}
function generatePagingResultsTextForTable(currentPage, itemsForCurrentPageLength, allItemsLength, maxNumItemsPerPage) {
    const firstItemNumberOnPage = generateFirstItemNumberOnPageForTable(currentPage, allItemsLength, maxNumItemsPerPage);
    const lastItemNumberOnPage = generateLastItemNumberOnPageForTable(currentPage, itemsForCurrentPageLength, allItemsLength, maxNumItemsPerPage);
    const result = `Showing ${firstItemNumberOnPage}-${lastItemNumberOnPage} of ${allItemsLength} results`;
    return result;
}
exports.generatePagingResultsTextForTable = generatePagingResultsTextForTable;
function generateFirstItemNumberOnPageForTable(currentPage, allItemsLength, maxNumItemsPerPage) {
    if (maxNumItemsPerPage < allItemsLength) {
        return (currentPage - 1) * maxNumItemsPerPage + 1;
    }
    else {
        return 1;
    }
}
exports.generateFirstItemNumberOnPageForTable = generateFirstItemNumberOnPageForTable;
function generateLastItemNumberOnPageForTable(currentPage, itemsForCurrentPageLength, allItemsLength, maxNumItemsPerPage) {
    if (maxNumItemsPerPage > allItemsLength) {
        return allItemsLength;
    }
    if (maxNumItemsPerPage < itemsForCurrentPageLength) {
        return itemsForCurrentPageLength;
    }
    if (currentPage * maxNumItemsPerPage > allItemsLength) {
        return allItemsLength;
    }
    return currentPage * maxNumItemsPerPage;
}
function calculateNumPagesForTable(filteredItemsLength, maxNumItemsPerPage) {
    return Math.ceil(filteredItemsLength / maxNumItemsPerPage);
}
exports.calculateNumPagesForTable = calculateNumPagesForTable;
function getUserFullName(user) {
    if (user === 'NOT_DEFINED' || user === null || user === undefined) {
        return '';
    }
    return user.first_name + ' ' + user.last_name;
}
exports.getUserFullName = getUserFullName;
function getContactNameForDisplay(contact) {
    if (!contact)
        return '';
    return contact.first_name + ' ' + contact.last_name;
}
exports.getContactNameForDisplay = getContactNameForDisplay;
function removeFormError(formErrors, label) {
    return formErrors.filter((error) => error.label !== label);
}
exports.removeFormError = removeFormError;
function formatISODateForHTMLInput(date) {
    return date.split('T')[0];
}
exports.formatISODateForHTMLInput = formatISODateForHTMLInput;
function shortCircuitFilterByGivenLength(items, shortCircuitLength, condition) {
    let filteredItems = [];
    for (let i = 0; i < items.length; i++) {
        if (filteredItems.length === shortCircuitLength) {
            break;
        }
        const item = items[i];
        if (item === undefined) {
            throw new Error('item is undefined');
        }
        if (condition(item) === true) {
            filteredItems.push(item);
        }
    }
    return filteredItems;
}
exports.shortCircuitFilterByGivenLength = shortCircuitFilterByGivenLength;
function createDebouncer() {
    let timeoutId;
    return (delay, callback) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
            callback();
        }, delay);
    };
}
exports.createDebouncer = createDebouncer;
function formatUSDCentsForDisplay(usdCents) {
    if (usdCents === 'NOT_FOUND') {
        return 'Unknown';
    }
    else {
        return `$${(usdCents / 100).toFixed(2)}`;
    }
}
exports.formatUSDCentsForDisplay = formatUSDCentsForDisplay;
function downloadCSV(csv, fileName) {
    const blob = new Blob([csv]);
    const blobURL = window.URL.createObjectURL(blob);
    let link = document.createElement('a');
    link.setAttribute('href', blobURL);
    link.setAttribute('download', fileName);
    document.body.appendChild(link); // TODO we might not even have to append this to the body
    link.click();
    document.body.removeChild(link);
}
exports.downloadCSV = downloadCSV;
function uploadCSV() {
    return new Promise(resolve => {
        let input = document.createElement('input');
        input.type = 'file';
        input.addEventListener('input', () => {
            const fileReader = new FileReader();
            fileReader.addEventListener('load', (e) => {
                const text = e.target?.result || '';
                resolve(text.toString());
            });
            if (input.files === null) {
                joAlert('Error', 'Could not upload file');
                throw new Error(`uploadCSV: input.files is null`);
            }
            const file = input.files[0];
            if (file === undefined) {
                joAlert('Error', 'Could not upload file');
                throw new Error(`uploadCSV: input.files is null`);
            }
            fileReader.readAsText(file);
        });
        input.click();
    });
}
exports.uploadCSV = uploadCSV;
function formateISODateForFileName(date) {
    return date.split('T')[0];
}
exports.formateISODateForFileName = formateISODateForFileName;
async function generateCSVForJoTable(params) {
    const columnHeadersCSV = generateColumnHeadersCSV(params.columns);
    const bodyCSV = await generateBodyCSV(params);
    return columnHeadersCSV + bodyCSV;
}
exports.generateCSVForJoTable = generateCSVForJoTable;
function generateColumnHeadersCSV(columns) {
    return (columns
        .map((currentColumn, index) => {
        if (currentColumn.title !== 'Actions') {
            return currentColumn.title;
        }
    }, '')
        .join(',') + '\n');
}
async function generateBodyCSV(params, bodyCSVResult = '') {
    if (params.getItems === 'NOT_SET') {
        if (params.items.length > 0) {
            return generateBodyCSVForCurrentItems(params.items, params.columns);
        }
        return bodyCSVResult;
    }
    const getItemsResult = await params.getItems(params.offset, params.limit, params.searchString, params.selectedColumn, params.items);
    if (getItemsResult.items.length === 0) {
        return bodyCSVResult;
    }
    const bodyCSV = generateBodyCSVForCurrentItems(getItemsResult.items, params.columns);
    return generateBodyCSV({
        ...params,
        offset: params.offset + params.limit,
    }, bodyCSVResult + bodyCSV);
}
function generateBodyCSVForCurrentItems(items, columns) {
    return items.reduce((result, currentItem) => {
        return (result +
            columns
                .map((column, index) => {
                if (column.title !== 'Actions') {
                    return getCellValueForCSV(column, currentItem, index, items);
                }
            })
                .join(',') +
            '\n');
    }, '');
}
function getCellValueForCSV(column, currentItem, index, allItems) {
    const functionToGetCellData = column.getCellDataForCSV ?? column.getCellData;
    const rawResult = functionToGetCellData(currentItem, index, allItems);
    const stringResult = String(rawResult);
    return stringResult.replace(/,/g, '');
}
function formatISODateForFileName(isoDate) {
    return isoDate.split('T')[0];
}
exports.formatISODateForFileName = formatISODateForFileName;
function getActualCallDurationString(highLevelStats) {
    return `${millisecondsToMinutes(parseInt(highLevelStats.actualCallDuration))} (${millisecondsToHoursMinutesSeconds(parseInt(highLevelStats.actualCallDuration))})`;
}
exports.getActualCallDurationString = getActualCallDurationString;
function getTotalBillableCallDurationString(highLevelStats) {
    return `${millisecondsToMinutes(parseInt(highLevelStats.billableCallDuration))} (${millisecondsToHoursMinutesSeconds(parseInt(highLevelStats.billableCallDuration))})`;
}
exports.getTotalBillableCallDurationString = getTotalBillableCallDurationString;
function getAverageEfficiencyPerDayString(highLevelStats) {
    return `${highLevelStats.averageEfficiencyPerDay.toFixed(2)}%`;
}
exports.getAverageEfficiencyPerDayString = getAverageEfficiencyPerDayString;
function getAverageBillableCallDurationString(highLevelStats) {
    return `${millisecondsToMinutes(highLevelStats.averageBillableCallDuration)} (${millisecondsToHoursMinutesSeconds(highLevelStats.averageBillableCallDuration)})`;
}
exports.getAverageBillableCallDurationString = getAverageBillableCallDurationString;
function getAverageActualCallDurationString(highLevelStats) {
    return `${millisecondsToMinutes(highLevelStats.averageActualCallDuration)} (${millisecondsToHoursMinutesSeconds(highLevelStats.averageActualCallDuration)})`;
}
exports.getAverageActualCallDurationString = getAverageActualCallDurationString;
function getAverageTimeBetweenCalls(highLevelStats) {
    return `${millisecondsToMinutes(highLevelStats.averageTimeBetweenCalls)} (${millisecondsToHoursMinutesSeconds(highLevelStats.averageTimeBetweenCalls)})`;
}
exports.getAverageTimeBetweenCalls = getAverageTimeBetweenCalls;
function getAverageActionItemTimeString(highLevelStats) {
    return `${millisecondsToMinutes(highLevelStats.averageActionItemTime)} (${millisecondsToHoursMinutesSeconds(highLevelStats.averageActionItemTime)})`;
}
exports.getAverageActionItemTimeString = getAverageActionItemTimeString;
function generateAndDownloadCSVForHighLevelStatses(highLevelStatses, highLevelStatsForMonthsCollapsed, fileName) {
    const csv = generateCSVForHighLevelStatses(highLevelStatses, highLevelStatsForMonthsCollapsed);
    downloadCSV(csv, fileName);
}
exports.generateAndDownloadCSVForHighLevelStatses = generateAndDownloadCSVForHighLevelStatses;
function generateCSVForHighLevelStatses(highLevelStatses, highLevelStatsForMonthsCollapsed) {
    const headerColumnsString = `Date,Total,Total Actual Call Duration,Total Billable Call Duration,Average Efficiency Per Day,Average Billable Call Duration,Average Actual Call Duration,Average Time Between Calls,Average Action Item Time\n`;
    const totalHighLevelStatsCSVString = generateCSVBodyForHighLevelStats(highLevelStatses.highLevelStats, 'Total');
    const perMonthHighLevelStatsCSVString = generateCSVStringForHighLevelStatsForMonths(highLevelStatses.highLevelStatsForMonths, highLevelStatsForMonthsCollapsed);
    return headerColumnsString + totalHighLevelStatsCSVString + perMonthHighLevelStatsCSVString;
}
exports.generateCSVForHighLevelStatses = generateCSVForHighLevelStatses;
function generateCSVBodyForHighLevelStats(highLevelStats, dateName) {
    const numCalls = `${highLevelStats.numCalls}`;
    const totalActualCallDuration = getActualCallDurationString(highLevelStats);
    const totalBillableCallDuration = getTotalBillableCallDurationString(highLevelStats);
    const averageEfficiencyPerDay = getAverageEfficiencyPerDayString(highLevelStats);
    const averageBillableCallDuration = getAverageBillableCallDurationString(highLevelStats);
    const averageActualCallDuration = getAverageActualCallDurationString(highLevelStats);
    const averageTimeBetweenCalls = getAverageTimeBetweenCalls(highLevelStats);
    const averageActionItemTime = getAverageActionItemTimeString(highLevelStats);
    const result = dateName +
        ',' +
        numCalls +
        ',' +
        totalActualCallDuration +
        ',' +
        totalBillableCallDuration +
        ',' +
        averageEfficiencyPerDay +
        ',' +
        averageBillableCallDuration +
        ',' +
        averageActualCallDuration +
        ',' +
        averageTimeBetweenCalls +
        ',' +
        averageActionItemTime +
        '\n';
    return result;
}
exports.generateCSVBodyForHighLevelStats = generateCSVBodyForHighLevelStats;
function generateCSVStringForHighLevelStatsForMonths(highLevelStatsForMonths, highLevelStatsForMonthsCollapsed) {
    const csv = highLevelStatsForMonths.reduce((result, currentHighLevelStatsForMonth) => {
        const csvForHighLevelStatsForCurrentMonth = generateCSVBodyForHighLevelStats(currentHighLevelStatsForMonth.highLevelStats, new Date(currentHighLevelStatsForMonth.dateInMonth).toLocaleString('en-us', {
            month: 'long',
            year: 'numeric',
        }));
        const highLevelStatsForMonthCollapsed = highLevelStatsForMonthsCollapsed.find((highLevelStatsForMonthCollapsed) => {
            return (new Date(highLevelStatsForMonthCollapsed.highLevelStatsForMonth.dateInMonth).getTime() ===
                new Date(currentHighLevelStatsForMonth.dateInMonth).getTime());
        });
        const csvForHIhLevelStatsForDaysOfCurrentMonth = highLevelStatsForMonthCollapsed?.collapsed === true
            ? ''
            : generateCSVStringForHighLevelStatsForDaysOfMonth(currentHighLevelStatsForMonth.highLevelStatsForDays);
        return (result +
            csvForHighLevelStatsForCurrentMonth +
            csvForHIhLevelStatsForDaysOfCurrentMonth);
    }, '');
    return csv;
}
exports.generateCSVStringForHighLevelStatsForMonths = generateCSVStringForHighLevelStatsForMonths;
function generateCSVStringForHighLevelStatsForDaysOfMonth(highLevelStatsForDays) {
    return highLevelStatsForDays.reduce((result, currentHighLevelStatsForDay) => {
        return (result +
            generateCSVBodyForHighLevelStats(currentHighLevelStatsForDay.highLevelStats, new Date(currentHighLevelStatsForDay.dateInDay).toLocaleDateString()));
    }, '');
}
function entityArraysEqual(entityArray1, entityArray2) {
    return entityArray1.reduce((result, entity1, index) => {
        if (result === false) {
            return result;
        }
        if (entityArray2[index] && entity1.id === entityArray2[index]?.id) {
            return true;
        }
        else {
            return false;
        }
    }, true);
}
exports.entityArraysEqual = entityArraysEqual;
function getCompanyServiceLineItems(companyServices) {
    const companyServiceLineItems = companyServices.reduce((result, service) => {
        return [
            ...result,
            ...service.line_items,
        ];
    }, []);
    return companyServiceLineItems;
}
exports.getCompanyServiceLineItems = getCompanyServiceLineItems;
async function wait(milliseconds) {
    await new Promise(resolve => setTimeout(resolve, milliseconds));
}
exports.wait = wait;
function getPhoneNumberForOutgoingCallForActionItem(actionItem) {
    const contact = actionItem.contact;
    if (contact === null || contact === undefined) {
        return {
            succeeded: false,
            userMessage: 'Contact not found for action item',
            developerMessage: `Contact not found for action item ${actionItem.id}`,
        };
    }
    const previousCall = actionItem.call;
    if (previousCall === null || previousCall === undefined) {
        const firstPhoneNumberResult = getFirstPhoneNumberForContact(contact);
        if (firstPhoneNumberResult.succeeded === false) {
            return firstPhoneNumberResult;
        }
        return {
            succeeded: true,
            value: firstPhoneNumberResult.value,
        };
    }
    const correctPhoneNumberToCallResult = getCorrectPhoneNumberToCallBasedOnPreviousCall(contact, previousCall);
    if (correctPhoneNumberToCallResult.succeeded === false) {
        return correctPhoneNumberToCallResult;
    }
    return {
        succeeded: true,
        value: correctPhoneNumberToCallResult.value,
    };
}
exports.getPhoneNumberForOutgoingCallForActionItem = getPhoneNumberForOutgoingCallForActionItem;
function getFirstPhoneNumberForContact(contact) {
    if (contact.phone_numbers.length > 0) {
        const sortedItems = sortItemsByTimeStamp(contact.phone_numbers, 'CREATED_AT', 'ASC');
        (0, common_1.assertLengthAtLeast)(sortedItems, 1);
        const firstNumber = sortedItems[0].number;
        return {
            succeeded: true,
            value: firstNumber,
        };
    }
    if (contact.phone_number === null || contact.phone_number === undefined) {
        return {
            succeeded: false,
            userMessage: 'Could not find phone number for contact',
            developerMessage: `Could not find phone number for contact: ${contact}`,
        };
    }
    return {
        succeeded: true,
        value: contact.phone_number,
    };
}
exports.getFirstPhoneNumberForContact = getFirstPhoneNumberForContact;
function getCorrectPhoneNumberToCallBasedOnPreviousCall(contact, previousCallForActionItem) {
    const phoneNumberToCall = contact.phone_numbers.find(phoneNumber => {
        if (previousCallForActionItem.new_direction === 'INCOMING') {
            return phoneNumber.number === previousCallForActionItem.origin_phone;
        }
        if (previousCallForActionItem.new_direction === 'OUTGOING') {
            return phoneNumber.number === previousCallForActionItem.destination_phone;
        }
        return null;
    })?.number ||
        sortItemsByTimeStamp(contact.phone_numbers, 'CREATED_AT', 'ASC')[0]?.number ||
        contact.phone_number;
    if (phoneNumberToCall === null || phoneNumberToCall === undefined) {
        return {
            succeeded: false,
            userMessage: 'Could not get phone number to call',
            developerMessage: `Could not get phone number to call, contact: ${contact}, previousCallForActionItem: ${previousCallForActionItem}`,
        };
    }
    return {
        succeeded: true,
        value: phoneNumberToCall,
    };
}
// TODO this is copied verbatim in jills-office-node, would be great to have them both in one place one day
function isInAuthRole(rolesToCheck, authenticatedUser) {
    if (authenticatedUser === 'NOT_SET') {
        return false;
    }
    if (rolesToCheck.includes('ALL_JILLS') && constants_1.jillAuthRoles.includes(authenticatedUser.auth_role)) {
        return true;
    }
    return rolesToCheck.includes(authenticatedUser.auth_role);
}
exports.isInAuthRole = isInAuthRole;
// TODO consider replacing all instances of this function with binding to the ?hidden attribute using isInAuthRole
function authorizedRender(roles, user, html) {
    if (user === 'NOT_SET') {
        return '';
    }
    if (roles.includes(user.auth_role)) {
        return html;
    }
    return '';
}
exports.authorizedRender = authorizedRender;
// TODO these rules need to be combed over and solidified with the biz dev team
// TODO these rules will probably need to be completely refactored
function isAllowedToUpdateUser(loggedInUser, userToUpdate) {
    if (loggedInUser === 'NOT_SET' || userToUpdate === 'NOT_SET') {
        return false;
    }
    // allow Brant to edit anyone
    if (loggedInUser.id === 2019) {
        return true;
    }
    const updateUserPermissions = {
        JILL_MANAGER: [
            'JILL',
            'JILL_OUTBOUND',
        ],
        JILL_BUSINESS_DEVELOPMENT: [
            'COMPANY_EMPLOYEE',
            'COMPANY_OWNER',
            'JILL',
            'JILL_OUTBOUND',
            'JILL_MANAGER',
        ],
        JILL_EXECUTIVE: [
            'COMPANY_EMPLOYEE',
            'COMPANY_OWNER',
            'JILL',
            'JILL_OUTBOUND',
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ],
    };
    if (userToUpdate === 'NEW_USER') {
        if (updateUserPermissions[loggedInUser.auth_role] !== undefined) {
            return true;
        }
        return false;
    }
    if (updateUserPermissions[loggedInUser.auth_role] !== undefined &&
        userToUpdate.id === loggedInUser.id) {
        return true;
    }
    if (updateUserPermissions[loggedInUser.auth_role]?.includes(userToUpdate.auth_role) === true) {
        return true;
    }
    return false;
}
exports.isAllowedToUpdateUser = isAllowedToUpdateUser;
// This function does a deep equality check of all of the properties in object1 with the properties of the same name in object2
// It is called a left check because the leftmost parameter, object1, is the object we are checking equality for
function deepLeftCheck(object1, object2) {
    try {
        return Object.keys(object1).every((object1Key) => {
            const object1Value = object1[object1Key];
            const object2Value = object2[object1Key];
            if (object1Value === null) {
                throw new Error(`object1Value is not defined! object1Value: ${JSON.stringify(object1Value)}, object1Key: ${JSON.stringify(object1Key)}`);
            }
            if (object2Value === null) {
                throw new Error(`object2Value is not defined! object2Value: ${{ object2Value }}, object1Key: ${{
                    object1Key,
                }}`);
            }
            if (object1Value.length === 0 && object2Value.length === 0) {
                return true;
            }
            else {
                return (0, dequal_1.dequal)(object1Value, object2Value);
            }
        });
    }
    catch (error) {
        joAlert('Error', 'Something went wrong');
        throw new Error(`deepLeftCheck error: ${error}`);
    }
}
exports.deepLeftCheck = deepLeftCheck;
function isLoading(loadingObject) {
    return Object.values(loadingObject).includes(true);
}
exports.isLoading = isLoading;
function isInternalTransfer(to) {
    return to.startsWith('client:');
}
exports.isInternalTransfer = isInternalTransfer;
function checkIfZipCodeIsValid(zipCode, zipCodes) {
    if (zipCodes === '') {
        return false;
    }
    else {
        const zipCodesArray = zipCodes.split(',').map(z => z.trim());
        return zipCodesArray.includes(zipCode.trim());
    }
}
exports.checkIfZipCodeIsValid = checkIfZipCodeIsValid;
function startOutgoingCallForActionItem(actionItem, authenticatedUser) {
    assertIsDefined(actionItem.company?.id, 'actionItem.contact', handleError);
    assertIsDefined(actionItem.contact, 'actionItem.contact', handleError);
    const phoneNumberResult = getPhoneNumberForOutgoingCallForActionItem(actionItem);
    assertSucceeded(phoneNumberResult, handleError);
    const phoneNumber = phoneNumberResult.value;
    assertIsSet(authenticatedUser, handleError, 'authenticatedUser');
    (0, twilio_1.startOutgoingCall)(actionItem.id, actionItem.contact.id, actionItem.company.id, phoneNumber, authenticatedUser.id);
}
exports.startOutgoingCallForActionItem = startOutgoingCallForActionItem;
function shouldDisableCallActionButtonsDuringCall(callScreenMode, transferState, incomingCallType, incomingTransferCompleted) {
    if (callScreenMode !== 'INCOMING_NEW_ACTION_ITEM' &&
        callScreenMode !== 'INCOMING_EXISTING_ACTION_ITEM' &&
        callScreenMode !== 'OUTGOING_NEW_ACTION_ITEM' &&
        callScreenMode !== 'OUTGOING_EXISTING_ACTION_ITEM') {
        return true;
    }
    if (transferState === 'NOT_SET' || incomingCallType === 'NOT_SET') {
        return true;
    }
    if (transferState === 'STARTED') {
        return true;
    }
    if (incomingCallType === 'TRANSFER' && incomingTransferCompleted === false) {
        return true;
    }
    return false;
}
exports.shouldDisableCallActionButtonsDuringCall = shouldDisableCallActionButtonsDuringCall;
function shouldDisableCallActionButtonDialPadDuringCall(callScreenMode, transferState, incomingCallType, incomingTransferCompleted) {
    if (callScreenMode !== 'INCOMING_NEW_ACTION_ITEM' &&
        callScreenMode !== 'INCOMING_EXISTING_ACTION_ITEM' &&
        callScreenMode !== 'OUTGOING_NEW_ACTION_ITEM' &&
        callScreenMode !== 'OUTGOING_EXISTING_ACTION_ITEM') {
        return true;
    }
    if (transferState === 'NOT_SET' || incomingCallType === 'NOT_SET') {
        return true;
    }
    return false;
}
exports.shouldDisableCallActionButtonDialPadDuringCall = shouldDisableCallActionButtonDialPadDuringCall;
function isAllowedToUpdateJillPods(authenticatedUser, jill) {
    if (authenticatedUser === 'NOT_SET' || jill === 'NOT_SET') {
        return false;
    }
    if (jill === 'NEW_USER') {
        if ([
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ].includes(authenticatedUser.auth_role)) {
            return true;
        }
        return false;
    }
    const updatePodsPermissions = {
        JILL_MANAGER: [
            'JILL',
            'JILL_OUTBOUND',
            'JILL_MANAGER',
        ],
        JILL_BUSINESS_DEVELOPMENT: [
            'JILL',
            'JILL_OUTBOUND',
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
        ],
        JILL_EXECUTIVE: [
            'JILL',
            'JILL_OUTBOUND',
            'JILL_MANAGER',
            'JILL_BUSINESS_DEVELOPMENT',
            'JILL_EXECUTIVE',
        ],
    };
    if (updatePodsPermissions[authenticatedUser.auth_role]?.includes(jill.auth_role)) {
        return true;
    }
    return false;
}
exports.isAllowedToUpdateJillPods = isAllowedToUpdateJillPods;
function createActionItemSlug() {
    const randomBytesBase64String = generateURLSafeBase64String();
    return `ai_${randomBytesBase64String}`;
}
exports.createActionItemSlug = createActionItemSlug;
function generateURLSafeBase64String() {
    const randomBytesHexString = randomBytes(8);
    const randomBytesBase64String = makeBase64URLSafe(btoa(randomBytesHexString).toLowerCase());
    return randomBytesBase64String;
}
exports.generateURLSafeBase64String = generateURLSafeBase64String;
function randomBytes(size) {
    return Array.from(window.crypto.getRandomValues(new Uint8Array(size)))
        .map((int) => int.toString(16).padStart(2, '0'))
        .join('');
}
exports.randomBytes = randomBytes;
function makeBase64URLSafe(base64String) {
    return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}
function getIsOnCallScreen(route) {
    return (route.callScreenMode === 'INCOMING_NEW_ACTION_ITEM' ||
        route.callScreenMode === 'INCOMING_EXISTING_ACTION_ITEM' ||
        route.callScreenMode === 'OUTGOING_NEW_ACTION_ITEM' ||
        route.callScreenMode === 'OUTGOING_EXISTING_ACTION_ITEM');
}
exports.getIsOnCallScreen = getIsOnCallScreen;
function isOnCallScreen() {
    const route = store_1.GlobalStore.route;
    return getIsOnCallScreen(route);
}
exports.isOnCallScreen = isOnCallScreen;
function isOnDashboard() {
    return window.location.pathname === '/';
}
exports.isOnDashboard = isOnDashboard;
async function sendNewActionItemNoteEmails(previousActionItemNotes, currentActionItemNotes) {
    try {
        const newActionItemNotes = getNewActionItemNotes(previousActionItemNotes, currentActionItemNotes);
        const result = await sendActionItemNoteEmails(newActionItemNotes);
        if (result.succeeded === false) {
            return result;
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('sendNewActionItemNoteEmails', error);
    }
}
exports.sendNewActionItemNoteEmails = sendNewActionItemNoteEmails;
function getNewActionItemNotes(previousActionItemNotes, currentActionItemNotes) {
    return currentActionItemNotes.filter((currentActionItemNote) => {
        return (previousActionItemNotes.some((previousActionItemNote) => {
            return previousActionItemNote.id === currentActionItemNote.id;
        }) === false);
    });
}
async function sendActionItemNoteEmails(actionItemNotes) {
    try {
        for (const newActionItemNote of actionItemNotes) {
            const sendNewActionItemNoteEmailResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                    mutation ($actionItemNoteId: Int!) {
                        sendNewActionItemNoteEmail(actionItemNoteId: $actionItemNoteId)
                    }
                `, {
                actionItemNoteId: newActionItemNote.id,
            });
            if (sendNewActionItemNoteEmailResult.succeeded === false) {
                return sendNewActionItemNoteEmailResult;
            }
        }
        return {
            succeeded: true,
        };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('sendActionItemNoteEmails', error);
    }
}
function inputValuesHaveChanged(currentInputValues, initialInputValues) {
    const { formErrors, ...currentInputValuesWithoutFormErrors } = currentInputValues;
    const { formErrors: formErrors2, ...initialInputValuesWithoutFormErrors } = initialInputValues;
    if (deepLeftCheck(currentInputValuesWithoutFormErrors, initialInputValuesWithoutFormErrors) ===
        true) {
        return false;
    }
    return true;
}
exports.inputValuesHaveChanged = inputValuesHaveChanged;
function getDateStringForDateInputInLocalTime(date) {
    const monthIndexes = {
        0: 1,
        1: 2,
        2: 3,
        3: 4,
        4: 5,
        5: 6,
        6: 7,
        7: 8,
        8: 9,
        9: 10,
        10: 11,
        11: 12,
    };
    return `${date.getFullYear()}-${monthIndexes[date.getMonth()]}-${date.getDate()}`;
}
exports.getDateStringForDateInputInLocalTime = getDateStringForDateInputInLocalTime;
function convertDateInputDateStringToLocalTimeISOString(dateInputDateString) {
    return new Date(new Date(dateInputDateString).getTime() + new Date().getTimezoneOffset() * 60000).toISOString();
}
exports.convertDateInputDateStringToLocalTimeISOString = convertDateInputDateStringToLocalTimeISOString;
// TODO figure out how to type this thing correctly
function refetchGetItems(element, methodName) {
    element[methodName] = element[methodName].bind(element);
    element.store.dispatch({
        type: 'RENDER',
    });
}
exports.refetchGetItems = refetchGetItems;
function closeSubscriptionsIfNecessary(subscriptions) {
    Object.values(subscriptions).forEach((subscription) => {
        if (subscription !== 'NOT_SET') {
            subscription.close();
        }
    });
}
exports.closeSubscriptionsIfNecessary = closeSubscriptionsIfNecessary;
function shouldShowContactSearchFunctionality(callScreenMode, actionItemId) {
    if (callScreenMode === 'INCOMING_NEW_ACTION_ITEM' ||
        callScreenMode === 'CHAT' ||
        (callScreenMode === 'ACTION_ITEM' && actionItemId === 'NOT_SET')) {
        return true;
    }
    return false;
}
exports.shouldShowContactSearchFunctionality = shouldShowContactSearchFunctionality;
function userIsJill(user) {
    return (user.auth_role === 'JILL' ||
        user.auth_role === 'JILL_OUTBOUND' ||
        user.auth_role === 'JILL_MANAGER' ||
        user.auth_role === 'JILL_BUSINESS_DEVELOPMENT' ||
        user.auth_role === 'JILL_EXECUTIVE');
}
exports.userIsJill = userIsJill;
function assertHasTimestamp(item, timeStampType) {
    if (timeStampType === 'CREATED_AT' &&
        (item['created_at'] === null || item['created_at'] === undefined)) {
        joAlert('Error', 'Something went wrong');
        throw new Error(`assertHasTimestamp error: item does not have created_at propery: item: ${item}`);
    }
    if (timeStampType === 'UPDATED_AT' &&
        (item['updated_at'] === null || item['updated_at'] === undefined)) {
        joAlert('Error', 'Something went wrong');
        throw new Error(`assertHasTimestamp error: item does not have updated_at propery: item: ${item}`);
    }
}
exports.assertHasTimestamp = assertHasTimestamp;
function sortItemsByTimeStamp(items, timeStampType, order) {
    const timestampProperty = timeStampType === 'CREATED_AT' ? 'created_at' : 'updated_at';
    return [...items].sort((a, b) => {
        assertHasTimestamp(a, timeStampType);
        assertHasTimestamp(b, timeStampType);
        if (order === 'DESC') {
            return (new Date(b[timestampProperty]).getTime() - new Date(a[timestampProperty]).getTime());
        }
        else {
            return (new Date(a[timestampProperty]).getTime() - new Date(b[timestampProperty]).getTime());
        }
    });
}
exports.sortItemsByTimeStamp = sortItemsByTimeStamp;
async function sendRouterRequest(path, body) {
    (0, logout_detector_1.detectLogout)();
    const bearer = await getBearer();
    const anonymousAuthJWT = window.localStorage.getItem(constants_1.anonymousAuthJWTName);
    const response = await window.fetch(`${mapped_env_variables_1.currentMappedEnvVariables.routerServerEndpoint}${path}`, {
        credentials: 'include',
        method: 'POST',
        headers: {
            authorization: `Bearer ${JSON.stringify(bearer)}`,
            ...(anonymousAuthJWT !== null
                ? {
                    'x-anonymous-jills-office-auth-jwt': anonymousAuthJWT,
                }
                : {}),
        },
        body,
    });
    return response;
}
exports.sendRouterRequest = sendRouterRequest;
// TODO this is copied from jills-office-node
const monthPrefixes = {
    0: 'january-',
    1: 'february-',
    2: 'march-',
    3: 'april-',
    4: 'may-',
    5: 'june-',
    6: 'july-',
    7: 'august-',
    8: 'september-',
    9: 'october-',
    10: 'november-',
    11: 'december-',
};
// TODO this is copied from jills-office-node
function getForMonthForDate(date) {
    const currentMonthIndex = date.getMonth();
    const currentMonthPrefix = monthPrefixes[currentMonthIndex];
    const year = date.getFullYear();
    const forMonth = `${currentMonthPrefix}${year}`;
    return forMonth;
}
exports.getForMonthForDate = getForMonthForDate;
function getStartOfMonthForForMonth(forMonth) {
    const forMonthDate = new Date(forMonth);
    return new Date(forMonthDate.getFullYear(), forMonthDate.getMonth(), 1);
}
exports.getStartOfMonthForForMonth = getStartOfMonthForForMonth;
function getEndOfMonthForForMonth(forMonth) {
    const forMonthDate = new Date(forMonth);
    return new Date(forMonthDate.getFullYear(), forMonthDate.getMonth() + 1, 0);
}
exports.getEndOfMonthForForMonth = getEndOfMonthForForMonth;
function setPropsReducer(state, action) {
    if (action.type === 'SET_PROPS') {
        return Object.keys(action.props).reduce((result, prop) => {
            assertPropIsValid(prop, action, state);
            return {
                ...result,
                [prop]: action.props[prop] ?? state[prop],
            };
        }, state);
    }
    return state;
}
exports.setPropsReducer = setPropsReducer;
function assertPropIsValid(prop, action, state) {
    if (action.type !== 'SET_PROPS') {
        return;
    }
    if (typeof prop !== 'string' || !(prop in action.props) || !(prop in state)) {
        joAlert('Error', 'something went wrong');
        throw new Error(`prop ${prop} is not a valid prop in action ${JSON.stringify(action)} or state: ${JSON.stringify(state)}`);
    }
}
function cloneDeep(object) {
    return Object.keys(object).reduce((result, key) => {
        if (typeof object[key] === 'object') {
            return {
                ...result,
                [key]: cloneDeep(object[key]),
            };
        }
        return {
            ...result,
            [key]: object[key],
        };
    }, {});
}
exports.cloneDeep = cloneDeep;
function sortActionItemsForTable(acionItems, sortDirection) {
    const actionItemsSortedByUpdatedAt = sortItemsByTimeStamp(acionItems, 'UPDATED_AT', sortDirection);
    const actionItemsWithSortedNotesAndPhoneNumbers = actionItemsSortedByUpdatedAt.map(actionItem => {
        const sortedNotes = sortItemsByTimeStamp(actionItem.action_item_notes, 'CREATED_AT', 'DESC');
        const contactWithOrderedPhoneNumbers = sortContactPhoneNumbers(actionItem.contact, 'ASC');
        return {
            ...actionItem,
            action_item_notes: sortedNotes,
            contact: contactWithOrderedPhoneNumbers,
        };
    });
    return actionItemsWithSortedNotesAndPhoneNumbers;
}
exports.sortActionItemsForTable = sortActionItemsForTable;
function sortContactPhoneNumbers(contact, sortDir) {
    if (contact === null || contact === undefined || contact.phone_numbers.length === 0)
        return contact;
    return {
        ...contact,
        phone_numbers: sortItemsByTimeStamp(contact.phone_numbers, 'CREATED_AT', sortDir),
    };
}
function getCurrentMonthName() {
    return new Date().toLocaleDateString('default', { month: 'long' });
}
exports.getCurrentMonthName = getCurrentMonthName;
function getCurrentYear() {
    return new Date().getFullYear();
}
exports.getCurrentYear = getCurrentYear;
function getYearsTillCurrentYear(params) {
    const currentYear = new Date().getFullYear();
    const numYears = currentYear - params.startYear + 1;
    return Array(numYears)
        .fill(null)
        .map((_, i) => currentYear - i);
}
exports.getYearsTillCurrentYear = getYearsTillCurrentYear;
function getAllMonthNames() {
    const currentYear = new Date().getFullYear();
    return Array(12)
        .fill(null)
        .map((_, monthIndex) => {
        return new Date(currentYear, monthIndex).toLocaleString('default', { month: 'long' });
    });
}
exports.getAllMonthNames = getAllMonthNames;
function formatMoneyInUSDCents(num) {
    const numStrToTwoDecimalPlaces = num.toFixed(2);
    const numStrWithoutDecimal = numStrToTwoDecimalPlaces.replace('.', '');
    return Number(numStrWithoutDecimal);
}
exports.formatMoneyInUSDCents = formatMoneyInUSDCents;
async function sendCallScreenRefreshedDuringCallAlert(jillId, callId) {
    await (0, graphql_1.gqlRequest)(mapped_env_variables_1.currentMappedEnvVariables.graphqlLightContainerEndpoint).execute((0, graphql_1.gql) `
            mutation ($jillId: Int!, $callId: Int!, $timeStamp: DateTime!) {
                sendCallScreenRefreshedDuringCallAlert(
                    jillId: $jillId
                    callId: $callId
                    timeStamp: $timeStamp
                )
            }
        `, {
        jillId,
        callId,
        timeStamp: new Date().toISOString(),
    });
}
exports.sendCallScreenRefreshedDuringCallAlert = sendCallScreenRefreshedDuringCallAlert;
function getUTCDate(givenDate) {
    const timezoneOffsetInMinutes = givenDate.getTimezoneOffset();
    const timezoneOffsetInSeconds = timezoneOffsetInMinutes * 60;
    const timezoneOffsetIneMilliseconds = timezoneOffsetInSeconds * 1000;
    const timeInMillisecondsForGivenDate = givenDate.getTime();
    const timeInMillisecondsForUTCEquivalent = timeInMillisecondsForGivenDate + timezoneOffsetIneMilliseconds;
    return new Date(timeInMillisecondsForUTCEquivalent);
}
exports.getUTCDate = getUTCDate;
function getCurrentForMonth() {
    const currentMonth = new Date().toLocaleString('default', { month: 'long' });
    const currentYear = new Date().getFullYear();
    return getForMonth(currentMonth, currentYear);
}
exports.getCurrentForMonth = getCurrentForMonth;
function getForMonth(monthName, year) {
    return `${monthName.toLowerCase()}-${year}`;
}
exports.getForMonth = getForMonth;
function getEndOfMonth(dateInMonth) {
    return new Date(dateInMonth.getFullYear(), dateInMonth.getMonth() + 1, 0);
}
exports.getEndOfMonth = getEndOfMonth;
function convertCentsToDollars(cents) {
    const amountInCents = cents;
    const amountInDollars = amountInCents / 100;
    const amountInDollarsToTwoDecimalPlaces = amountInDollars.toFixed(2);
    return Number(amountInDollarsToTwoDecimalPlaces);
}
exports.convertCentsToDollars = convertCentsToDollars;
function convertCentsToUSDollarsString(cents) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    const dollars = convertCentsToDollars(cents);
    return formatter.format(dollars);
}
exports.convertCentsToUSDollarsString = convertCentsToUSDollarsString;
function getBillingDateRangeFromForMonth(forMonth) {
    if (!forMonth)
        return 'Unknown';
    const beginningOfMonth = new Date(forMonth);
    const endOfMonth = new Date(beginningOfMonth.getFullYear(), beginningOfMonth.getMonth() + 1, 0);
    return `${beginningOfMonth.toLocaleDateString()} - ${endOfMonth.toLocaleDateString()}`;
}
exports.getBillingDateRangeFromForMonth = getBillingDateRangeFromForMonth;
function getMonthFromForMonth(forMonth) {
    const forMonthParts = forMonth.split('-');
    (0, common_1.assertLengthAtLeast)(forMonthParts, 1);
    const month = forMonthParts[0];
    const monthCapitalized = capitalizeFirstLetter(month);
    return monthCapitalized;
}
exports.getMonthFromForMonth = getMonthFromForMonth;
function capitalizeFirstLetter(str) {
    const firstLetter = str[0];
    const remainingLetters = str.slice(1);
    if (firstLetter == null) {
        throw new Error('firstLetter is null');
    }
    return firstLetter.toUpperCase() + remainingLetters;
}
exports.capitalizeFirstLetter = capitalizeFirstLetter;
function getYearFromForMonth(forMonth) {
    const yearString = forMonth.split('-')[1];
    if (yearString === undefined) {
        return getCurrentYear();
    }
    return parseInt(yearString);
}
exports.getYearFromForMonth = getYearFromForMonth;
function isNewCompanyId(currentCompanyId, previousCompanyId) {
    return currentCompanyId !== 'NOT_SET' && previousCompanyId !== currentCompanyId;
}
exports.isNewCompanyId = isNewCompanyId;
function getIsCompanyUser(authenticatedUser, companyId) {
    return getIsUserMemberOfCompanyAndInAuthRole(authenticatedUser, companyId, [
        'COMPANY_OWNER',
        'COMPANY_EMPLOYEE',
    ]);
}
exports.getIsCompanyUser = getIsCompanyUser;
function getIsCompanyOwner(authenticatedUser, companyId) {
    return getIsUserMemberOfCompanyAndInAuthRole(authenticatedUser, companyId, ['COMPANY_OWNER']);
}
exports.getIsCompanyOwner = getIsCompanyOwner;
function getIsUserMemberOfCompanyAndInAuthRole(authenticatedUser, companyId, authRoles) {
    if (companyId === 'NOT_SET' || authenticatedUser === 'NOT_SET') {
        return false;
    }
    return (isInAuthRole(authRoles, authenticatedUser) &&
        authenticatedUser.companies.some(company => company.id === companyId));
}
exports.getIsUserMemberOfCompanyAndInAuthRole = getIsUserMemberOfCompanyAndInAuthRole;
async function sendActionItemPushNotification(companyId) {
    try {
        const gqlResult = await (0, graphql_1.gqlRequestResult)(mapped_env_variables_1.currentMappedEnvVariables.graphqlHeavyContainerEndpoint).execute((0, graphql_1.gql) `
                mutation ($companyId: Int!) {
                    sendPushNotificationToUsersForCompany(
                        companyId: $companyId
                        title: "New Note"
                        body: "You have an unread message from Jill's Office!"
                    )
                }
            `, {
            companyId,
        });
        if (gqlResult.succeeded === false)
            return gqlResult;
        return { succeeded: true };
    }
    catch (error) {
        return (0, error_handling_1.genericJOFailure)('sendActionItemPushNotification', error);
    }
}
exports.sendActionItemPushNotification = sendActionItemPushNotification;
function trimExtraWhiteSpace(str) {
    return str.trim().replace(/\s{2,}/, ' ');
}
exports.trimExtraWhiteSpace = trimExtraWhiteSpace;
function isUserAMemberOfJillsOffice(user) {
    if (!user || user === 'NOT_SET')
        return false;
    const jillRoles = [
        'JILL',
        'JILL_OUTBOUND',
        'JILL_MANAGER',
        'JILL_BUSINESS_DEVELOPMENT',
        'JILL_EXECUTIVE',
    ];
    return jillRoles.includes(user.auth_role);
}
exports.isUserAMemberOfJillsOffice = isUserAMemberOfJillsOffice;
function formatDateForActivityLog(date) {
    const dateString = new Date(date).toDateString();
    const timeString = new Date(date).toLocaleTimeString();
    return `${dateString} at ${timeString}`;
}
exports.formatDateForActivityLog = formatDateForActivityLog;
function hasPermissionToManageJillProfilePictures(user) {
    if (user === 'NOT_SET')
        return false;
    if (isInAuthRole([
        'JILL_BUSINESS_DEVELOPMENT',
        'JILL_EXECUTIVE',
    ], user))
        return true;
    return (user.profile_picture_management_privileges === 'ALL_PRIVILEGES' ||
        user.profile_picture_management_privileges === 'JILL_PROFILE_PICTURES_ONLY');
}
exports.hasPermissionToManageJillProfilePictures = hasPermissionToManageJillProfilePictures;
function areObjectsEqualMinusFormErrors(firstObj, secondObj) {
    return (doesObjectBContainEverythingInObjectAExcludingFormErrors(firstObj, secondObj) &&
        doesObjectBContainEverythingInObjectAExcludingFormErrors(secondObj, firstObj));
}
exports.areObjectsEqualMinusFormErrors = areObjectsEqualMinusFormErrors;
function doesObjectBContainEverythingInObjectAExcludingFormErrors(objA, objB) {
    const keys = Object.keys(objA);
    for (const key of keys) {
        if (key.toLowerCase() === 'formerrors')
            continue;
        else if (Array.isArray(objA[key])) {
            if (!Array.isArray(objB[key]))
                return false;
            if (!(0, dequal_1.dequal)(objA[key], objB[key]))
                return false;
        }
        else if (typeof objA[key] === 'object') {
            if (!objB[key] || typeof objB[key] !== 'object')
                return false;
            if (!doesObjectBContainEverythingInObjectAExcludingFormErrors(objA[key], objB[key]))
                return false;
        }
        else {
            if (objA[key] !== objB[key])
                return false;
        }
    }
    return true;
}
exports.doesObjectBContainEverythingInObjectAExcludingFormErrors = doesObjectBContainEverythingInObjectAExcludingFormErrors;
function makeDateTwoChar(date) {
    let dateString = new Date(date).toLocaleDateString('en-US').split('/');
    (0, common_1.assertLengthAtLeast)(dateString, 3);
    let year = dateString[2];
    dateString[2] = year.slice(2, 4);
    return dateString.join('/');
}
exports.makeDateTwoChar = makeDateTwoChar;
function isMobileView() {
    return window.matchMedia(constants_1.MOBILE_DEVICE_MEDIA_QUERY).matches;
}
exports.isMobileView = isMobileView;
function shouldHideFromCompanyUser(authUser) {
    if (authUser !== 'NOT_SET') {
        return (0, auth_rules_1.isCompanyUser)(authUser);
    }
    return true;
}
exports.shouldHideFromCompanyUser = shouldHideFromCompanyUser;
function getStartOfToday() {
    return getStartOfDate(new Date());
}
exports.getStartOfToday = getStartOfToday;
function getStartOfDate(date) {
    const startOfDate = new Date(date.getTime());
    startOfDate.setHours(0, 0, 0, 0);
    return startOfDate;
}
exports.getStartOfDate = getStartOfDate;
